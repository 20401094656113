import { ArrowLeftRight, ArrowRight } from 'lucide-react';
import { AirlineList } from 'ui/components/AirlinePicker';
import BulletIndicator, {
	getIntentForSubscriptionStatus,
} from 'ui/components/BulletIndicator/BulletIndicator';
import Card from 'ui/components/Card/Card';
import Flex from 'ui/components/Flex';
import { FreightForwarderList } from 'ui/components/FreightForwarderPicker';
import KeyValuePairs, {
	KeyValue,
} from 'ui/components/KeyValuePairs/KeyValuePairs';
import { LocationList } from 'ui/components/LocationPicker/LocationList';
import Pill from 'ui/components/Pill';
import WeightBreaks from 'ui/components/WeightBreaks';
import { DEFAULT_WEIGHT_BREAKS_CDD } from 'ui/components/WeightBreaks/WeightBreaks';
import YearAndMonthFragment from 'ui/components/YearAndMonthFragment';
import {
	AllProductDetails,
	isWebToolAirlineConfig,
	isWebToolFreightForwarderConfig,
} from '../../api/SubscriptionAPI';
import { ProductTypeCode } from '../../util/schemas/subscriptionSchema';
import {
	doesProductHaveFactory,
	productConfigEditorSettings,
} from './ProductConfigurationEditor';

type ProductConfigurationCardProps = {
	config: Partial<AllProductDetails> & { type: ProductTypeCode };
};

const ProductConfigurationCard = ({
	config,
}: ProductConfigurationCardProps) => {
	const doesProductHave = doesProductHaveFactory(config.type);

	const keyValuePairs: KeyValue[] = [];

	const editorSettings = config.type
		? productConfigEditorSettings[config.type]
		: null;

	const isMultiAirlines = editorSettings?.airlines?.isMulti;
	const isMultiForwarders = editorSettings?.freightForwarders?.isMulti;
	const isMultiOrigins = editorSettings?.origin?.isMulti;
	const isMultiDestinations = editorSettings?.destination?.isMulti;

	function showHistoricalData() {
		if (editorSettings?.yearsOfHistoricalData?.type === 'checkbox') {
			return (
				<>
					<p>{config.yearsOfHistoricalData === 1 ? 'Yes' : 'No'}</p>
				</>
			);
		} else if (editorSettings?.yearsOfHistoricalData?.type === 'dropdown') {
			return (
				<>
					<p>
						{config.yearsOfHistoricalData === undefined ||
						config.yearsOfHistoricalData === 0
							? 'No'
							: `${config.yearsOfHistoricalData} Years`}
					</p>
				</>
			);
		} else {
			return (
				<>
					<p>Historical data is not available for this product</p>
				</>
			);
		}
	}

	if (doesProductHave('airlines')) {
		keyValuePairs.push({
			key: isMultiAirlines ? 'Airlines' : 'Airline',
			value: <AirlineList airlines={config.airlines} emptyText="-" />,
		});
	}

	if (doesProductHave('freightForwarders')) {
		keyValuePairs.push({
			key: isMultiForwarders ? 'Freight Forwarders' : 'Freight Forwarder',
			value: (
				<FreightForwarderList
					freightForwarders={config.freightForwarders}
					emptyText={'-'}
				/>
			),
		});
	}

	if (doesProductHave('nddContributors')) {
		keyValuePairs.push({
			key: 'NDD Contributors',
			value:
				(config.nddContributors?.length ?? 0) > 0
					? config.nddContributors?.map((field) => (
							<>
								<Pill key={field.value} name={field.label} />
								&nbsp;
							</>
						))
					: '-',
		});
	}

	if (doesProductHave('origin')) {
		keyValuePairs.push({
			key: isMultiOrigins ? 'Origins' : 'Origin',
			value: <LocationList locations={config.origin} emptyText={'World'} />,
		});
	}

	if (doesProductHave('destination')) {
		keyValuePairs.push({
			key: isMultiDestinations ? 'Destinations' : 'Destination',
			value: (
				<LocationList locations={config.destination} emptyText={'World'} />
			),
		});
	}

	if (hasItems(config.origin2) || hasItems(config.destination2)) {
		keyValuePairs.push(
			{
				key: 'Origin 2',
				value: <LocationList locations={config.origin2} emptyText={'World'} />,
			},
			{
				key: 'Destination 2',
				value: (
					<LocationList locations={config.destination2} emptyText={'World'} />
				),
			}
		);
	}

	if (hasItems(config.origin3) || hasItems(config.destination3)) {
		keyValuePairs.push(
			{
				key: 'Origin 3',
				value: <LocationList locations={config.origin3} emptyText={'World'} />,
			},
			{
				key: 'Destination 3',
				value: (
					<LocationList locations={config.destination3} emptyText={'World'} />
				),
			}
		);
	}

	if (doesProductHave('groupConsortium')) {
		keyValuePairs.push({
			key: 'Group Consortium',
			value: config.groupConsortium ? 'Yes' : 'No',
		});
	}

	if (doesProductHave('includeOtherCharges')) {
		keyValuePairs.push({
			key: 'Include Other Charges',
			value: config.includeOtherCharges ? 'Yes' : 'No',
		});
	}

	if (doesProductHave('yearsOfHistoricalData')) {
		keyValuePairs.push({
			key: 'Historical data',
			value: showHistoricalData(),
		});
	}

	if (doesProductHave('isSpecialBipReport')) {
		keyValuePairs.push({
			key: 'Is this a special format BIP report?',
			value: config.isSpecialBipReport ? 'Yes' : 'No',
		});
	}

	if (doesProductHave('currency')) {
		keyValuePairs.push({
			key: 'Currency',
			value: config.currency?.label ?? '-',
		});
	}

	if (doesProductHave('timePeriodInterval')) {
		keyValuePairs.push({
			key: 'Time Period Interval',
			value: config.timePeriodInterval?.label ?? '-',
		});
	}

	if (doesProductHave('aggregation')) {
		keyValuePairs.push({
			key: 'Aggregate By',
			value: config.aggregation?.label ?? '-',
		});
	}

	if (doesProductHave('dataFileSeparator')) {
		keyValuePairs.push({
			key: 'Data File Separator',
			value: config.dataFileSeparator?.label,
		});
	}

	if (doesProductHave('referenceFileSeparator')) {
		keyValuePairs.push({
			key: 'Reference File Separator',
			value: config.referenceFileSeparator?.label,
		});
	}

	if (doesProductHave('iiNetAccountId')) {
		keyValuePairs.push({
			key: 'iiNET Account ID',
			value: config.iiNetAccountId ?? '-',
		});
	}

	if (doesProductHave('lengthOfRanking')) {
		keyValuePairs.push({
			key: 'Length of Ranking',
			value: config.lengthOfRanking,
		});
	}

	if (doesProductHave('minimumChargeableCompetitiveWeight')) {
		keyValuePairs.push({
			key: 'Minimum Chargable Competitive Weight (kg/year)',
			value: config.minimumChargeableCompetitiveWeight ?? '-',
		});
	}

	if (doesProductHave('scopeType')) {
		keyValuePairs.push({
			key: 'Scope Type',
			value: config.scopeType?.label,
		});
	}

	if (doesProductHave('gsa') && config.gsa) {
		keyValuePairs.push({
			key: 'GSA',
			value: <Pill code={config.gsa?.code} name={config.gsa?.name} />,
		});
	}

	if (
		doesProductHave('contributorCode') &&
		config.contributorCode &&
		doesProductHave('contributorName') &&
		config.contributorName
	) {
		keyValuePairs.push({
			key: 'Contributor',
			value: (
				<Pill code={config.contributorCode} name={config.contributorName} />
			),
		});
	}

	if (doesProductHave('submissionStatus')) {
		keyValuePairs.push({
			key: 'Submission Status',
			value: (
				<BulletIndicator
					label={config.submissionStatus!.label}
					intent={getIntentForSubscriptionStatus(
						config.submissionStatus!.value
					)}
				/>
			),
		});
	}

	if (doesProductHave('directDataFormat')) {
		keyValuePairs.push({
			key: 'Direct Data File Format',
			value: config.directDataFormat?.label,
		});
	}

	if (doesProductHave('webToolDataSource')) {
		keyValuePairs.push({
			key: 'Data Source',
			value: config.webToolDataSource?.label,
		});
	}

	if (doesProductHave('webToolDataSubscription')) {
		keyValuePairs.push({
			key: 'Data Subscription',
			value: config.webToolDataSubscription?.label,
		});
	}

	if (doesProductHave('webToolDataGroups')) {
		for (let [index, group] of (config.webToolDataGroups ?? []).entries()) {
			let groupDetails: KeyValue[] = [];
			if (isWebToolAirlineConfig(config)) {
				groupDetails.push({
					key: 'Airlines',
					value: (
						<AirlineList
							variant="small"
							airlines={config.webToolDataGroups[index].airlines}
							emptyText={'World'}
						/>
					),
				});
			}

			if (isWebToolFreightForwarderConfig(config)) {
				groupDetails.push({
					key: 'Freight Forwarders',
					value: (
						<FreightForwarderList
							freightForwarders={
								config.webToolDataGroups[index].freightForwarders
							}
							emptyText={'-'}
						/>
					),
				});

				if (config.webToolDataSource.value === 'ndd') {
					groupDetails.push({
						key: 'NDD Contributors',
						value: (
							<>
								{config.webToolDataGroups[index].nddContributors?.length > 0
									? config.webToolDataGroups[index].nddContributors.map(
											(field) => (
												<>
													<Pill key={field.value} name={field.label} />
													&nbsp;
												</>
											)
										)
									: '-'}
							</>
						),
					});
				}
			}

			groupDetails.push(
				{
					key: 'Origin / Destination',
					value: (
						<>
							<Flex gap={4}>
								<Flex gap={4}>
									<LocationList
										variant="small"
										locations={group.origin}
										emptyText={'World'}
									/>
								</Flex>
								<Flex>
									{group.includeReverseRoutes ? (
										<ArrowLeftRight />
									) : (
										<ArrowRight />
									)}
								</Flex>
								<Flex gap={4}>
									<LocationList
										variant="small"
										locations={group.destination}
										emptyText={'World'}
									/>
								</Flex>
							</Flex>
						</>
					),
				},
				{
					key: 'Period',
					value: (
						<>
							<YearAndMonthFragment value={group.startPeriod} /> -{' '}
							<YearAndMonthFragment value={group.endPeriod} />
						</>
					),
				}
			);
			if ((config.webToolDataGroups?.length ?? 0) > 1) {
				keyValuePairs.push({
					key:
						(config.webToolDataGroups?.length ?? 0) > 1
							? `Geographical Group ${index + 1}`
							: 'Geographical Group',
					value: (
						<Card>
							<KeyValuePairs layout="vertical" entries={groupDetails} />
						</Card>
					),
				});
			} else {
				keyValuePairs.push(...groupDetails);
			}
		}
	}

	if (doesProductHave('webToolHiddenFields') && config.webToolHiddenFields) {
		keyValuePairs.push({
			key: 'Hidden Fields',
			value: (
				<>
					{config.webToolHiddenFields.map((field) => (
						<Pill key={field.value} name={field.label} />
					))}
				</>
			),
		});
	}

	if (doesProductHave('marketBreakdown')) {
		keyValuePairs.push({
			key: 'Market with Customer Breakdown',
			value: config.marketBreakdown ? 'Included' : 'Excluded',
		});
	}

	if (doesProductHave('weightBreaks')) {
		keyValuePairs.push({
			key: 'Weight Breaks',
			value: !config.weightBreaks ? (
				'IATA Groupings'
			) : (
				<WeightBreaks initialValue={config.weightBreaks} isDisabled />
			),
		});
	}

	if (doesProductHave('weightBreaksCdd')) {
		keyValuePairs.push({
			key: 'CDD Weight Breaks',
			value: !config.weightBreaks ? (
				'IATA Groupings'
			) : (
				<WeightBreaks
					weightBreaks={DEFAULT_WEIGHT_BREAKS_CDD}
					initialValue={config.weightBreaksCdd}
					isDisabled
				/>
			),
		});
	}

	return (
		<Card label="Product Configuration">
			<KeyValuePairs layout="vertical" entries={keyValuePairs} />
		</Card>
	);
};

function hasItems(array: unknown[] | undefined) {
	return array && array.length > 0;
}

export default ProductConfigurationCard;
